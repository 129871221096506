import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"px-0"},[(_vm.isLoading)?_c(VProgressLinear,{style:(_vm.loaderStyles),attrs:{"indeterminate":"","height":_vm.loaderHeight}}):_vm._e(),_c('div',{ref:"container"}),_c(VRow,{staticClass:"pt-2"},[_c(VCol,{staticClass:"align-self-center caption"},[_vm._v(" "+_vm._s(_vm.position)+" ")]),_c(VCol,{staticClass:"align-self-center text-center"},[_c(VBtn,{attrs:{"color":"primary","fab":"","small":"","disabled":_vm.isLoading},on:{"click":_vm.toggle}},[_c(VIcon,[_vm._v(_vm._s(_vm.isPlaying ? 'pause' : 'play'))])],1)],1),_c(VCol,{staticClass:"align-self-center text-right caption"},[_vm._v(" "+_vm._s(_vm.duration)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }