<template>
    <v-container class="amp-module-page">
        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="podcast.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />
                <h2 v-else class="font-weight-medium text-justify">
                    {{ podcast.headline || '&#160;' }}
                </h2>
                <v-divider class="my-4" />
            </template>
        </amp-row-first>
        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="list-item-three-line"
                    class="mx-n4"
                />
                <!--  eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="podcast.summary || '&#160;'"
                ></h3>
                <!--  eslint-enable vue/no-v-html -->
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row v-if="isGenerating">
            <template #input>
                <v-row class="my-0">
                    <v-col class="my-12 text-center">
                        <v-progress-linear indeterminate height="2" />
                        <div class="mt-6">Generating audio...</div>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row v-if="canPreviewVoice">
            <template #input>
                <audio-wave-visualizer :src="podcast.preview_file" />
                <v-col class="px-0">
                    <a-alert type="info">
                        This podcast is a preview, please note that the full
                        version of a podcast will be distributed. To review the
                        full version, please download it.
                    </a-alert>
                </v-col>
                <v-col cols="12" class="text-right px-0 pt-0">
                    <v-btn
                        v-if="podcast.preview_file"
                        text
                        :block="$vuetify.breakpoint.smAndDown"
                        :download="podcast.preview_file"
                        :href="podcast.preview_file + '?_=' + cacheBustingTag"
                    >
                        <v-icon small left>download</v-icon>
                        Download full Podcast
                    </v-btn>
                </v-col>
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row v-if="!isLoading && needsRegeneration">
            <template #input>
                <v-col cols="12" class="text-right px-0 pt-0">
                    <v-btn
                        v-if="!isBusy"
                        text
                        :block="$vuetify.breakpoint.smAndDown"
                        :loading="isEnqueuing"
                        @click="enqueueForAudioGeneration()"
                    >
                        <v-icon small left>refresh</v-icon>
                        Generate Podcast
                    </v-btn>
                </v-col>
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row>
            <template #input>
                <media-preview-list
                    v-if="!isLoading"
                    class="pt-6 pb-4 px-1"
                    :media-resources="podcast.media_resources"
                    :allow-edit="false"
                    :allow-add="false"
                    :allow-delete="false"
                />
            </template>
        </amp-row>
        <amp-row v-if="company">
            <template #input>
                <v-row class="my-0">
                    <v-col class="pt-0">
                        <v-list-item-subtitle
                            class="light-black--text font-weight-medium"
                        >
                            Provided by
                        </v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                            {{ company.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="press_release"
                            class="secondary-color-light--text"
                        >
                            {{ publishedAt }}
                        </v-list-item-subtitle>
                    </v-col>
                    <v-col class="pt-0 text-right">
                        <v-btn
                            v-if="company.twitter_handle"
                            icon
                            :href="'https://x.com/' + company.twitter_handle"
                            target="_blank"
                        >
                            <v-icon>fab fa-square-x-twitter</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="company.facebook_page"
                            icon
                            :href="
                                'https://facebook.com/' + company.facebook_page
                            "
                            target="_blank"
                        >
                            <v-icon>fab fa-facebook-square</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
            </template>
        </amp-row>
        <amp-row-last>
            <template #input>
                <v-row class="my-0">
                    <v-col cols="12" md="8">
                        <company-card-small :company="company" flat />
                    </v-col>
                </v-row>
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';
import { getTimezone } from '@/utils/helpers/formatDate';

import { AAlert } from '@/components/AAlert';
import { AudioWaveVisualizer } from '@/components/AudioWaveVisualizer';
import { MediaPreviewList } from '@/components/Media';
import { CompanyCardSmall } from '@/components/CompanyCardSmall';
import { StatusChip } from '@/components/StatusChip';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';

import type { AmpModules } from '@/types/Announcement';
import type { Podcast as TPodcast } from '@/types/Podcast';
import { PodcastStatus } from '@/types/Podcast';
import type { ModuleLink } from '@/types/ModuleLink';

@Component({
    components: {
        AAlert,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AudioWaveVisualizer,
        MediaPreviewList,
        CompanyCardSmall,
        StatusChip
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class Slideshow extends mixins(Endpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    isEnqueuing = false;

    podcast: Partial<TPodcast> = {};

    endpoint = '/podcasts/review';

    queue: ReturnType<typeof setTimeout> | void = void 0;

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: false //this.isClient
            }
        ];
    }

    get moduleId() {
        return this.modules?.podcast_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return `/announcements/edit/${this.announcementId}/podcast`;
    }

    get cacheBustingTag() {
        return new Date().getTime();
    }

    get company() {
        return this.podcast?.announcement?.company;
    }

    get press_release() {
        return this.podcast.announcement?.press_release;
    }

    get publishedAt() {
        if (
            this.press_release?.pr_publish_date &&
            this.press_release?.pr_publish_time
        ) {
            const stamp = `${this.press_release.pr_publish_date} ${this.press_release.pr_publish_time}`;

            return `${stamp} ${getTimezone(
                stamp,
                'MMM DD, YYYY h:mm a',
                this.press_release.timezone
            )}`;
        }

        return '';
    }

    get isGenerating() {
        return (
            this.podcast.is_awaiting_audio_preview ||
            this.podcast?.status === PodcastStatus.AwaitingFinalAudio
        );
    }

    get isAwaitingDistribution() {
        return (
            this.podcast?.status === PodcastStatus.AwaitingDistribution ||
            this.podcast?.status === PodcastStatus.AwaitingFinalAudio
        );
    }

    get isBusy() {
        return this.isLoading || this.isGenerating;
    }

    get canPreviewVoice() {
        return !this.isBusy && this.hasPreviewFile;
    }

    get hasPreviewFile() {
        return Boolean(this.podcast && this.podcast?.preview_file);
    }

    get isLive() {
        return this.podcast?.status === PodcastStatus.Live;
    }

    get needsRegeneration() {
        return (
            !this.isGenerating &&
            !this.hasPreviewFile &&
            !(this.isAwaitingDistribution || this.isLive)
        );
    }

    get isEditable() {
        return this.podcast.is_editable;
    }

    get status() {
        return this.podcast.status_string ?? '';
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        } else {
            this.edit();
        }
    }

    destroyed() {
        if (this.queue) {
            clearTimeout(this.queue);
        }
    }

    onData(data: { podcast: TPodcast }) {
        this.podcast = data.podcast;

        this.emitLinks();

        this.monitorGenerationStatus();
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }

    queueCheck() {
        if (this.queue) {
            clearTimeout(this.queue);
        }

        this.queue = setTimeout(() => this.update(true), 30000);
    }

    edit() {
        this.$router.push(this.editLink);
    }

    monitorGenerationStatus() {
        if (this.isGenerating) {
            this.queueCheck();
        }
    }

    enqueueForAudioGeneration() {
        this.setEnqueuing();
        this.$http
            .post(`/podcasts/edit/${this.moduleId}`, { preview: true })
            .then(({ data }) => {
                if (data.data.podcast) {
                    this.podcast = data.data.podcast;
                    this.monitorGenerationStatus();
                }
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to enqueue Podcast for audio generation. Please check edit page and try again.'
                );
            })
            .finally(this.setEnqueuing.bind(this, false));
    }

    setEnqueuing(isEnqueuing = true) {
        this.isEnqueuing = isEnqueuing;
    }
}
</script>
