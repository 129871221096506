<template>
    <v-container class="px-0">
        <v-progress-linear
            v-if="isLoading"
            indeterminate
            :height="loaderHeight"
            :style="loaderStyles"
        />
        <div ref="container"></div>
        <v-row class="pt-2">
            <v-col class="align-self-center caption">
                {{ position }}
            </v-col>
            <v-col class="align-self-center text-center">
                <v-btn
                    color="primary"
                    fab
                    small
                    :disabled="isLoading"
                    @click="toggle"
                >
                    <v-icon>{{ isPlaying ? 'pause' : 'play' }}</v-icon>
                </v-btn>
            </v-col>
            <v-col class="align-self-center text-right caption">
                {{ duration }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { formatDate } from '@/utils/helpers';

import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';

const AudioWaveVisualizerProps = Vue.extend({
    name: 'AudioWaveVisualizer',
    props: {
        src: {
            type: String,
            default() {
                return '';
            }
        },
        height: {
            type: Number,
            default() {
                return 120;
            }
        }
    }
});

@Component
export default class AudioWaveVisualizer extends AudioWaveVisualizerProps {
    $refs!: {
        container: HTMLDivElement;
    };

    isPlaying = false;

    isLoading = true;

    waveSurfer: WaveSurfer | null = null;

    position = this.format(0);

    duration = '';

    loaderHeight = 2;

    get cacheBustingTag() {
        return new Date().getTime();
    }

    get loaderStyles() {
        const middle = this.height / 2;
        const heightDelta = this.loaderHeight / 2;

        return {
            marginTop: `${middle - heightDelta}px`,
            marginBottom: `${(middle + heightDelta) * -1}px`
        };
    }

    @Watch('src')
    onSrcChanged() {
        if (this.src) {
            this.load();
        }
    }

    mounted() {
        this.recreate();
    }

    beforeDestroy() {
        this.reset();
    }

    recreate() {
        this.reset();

        this.waveSurfer = WaveSurfer.create({
            container: this.$refs.container,
            waveColor: '#FE0D66', // $primary-color
            progressColor: '#85A5C2', // $secondary-color-light
            barWidth: 3,
            barRadius: 3,
            cursorWidth: 1,
            barGap: 3,
            height: this.height,
            responsive: true, // redraw on resize
            plugins: [
                CursorPlugin.create({
                    showTime: true,
                    opacity: '1',
                    customShowTimeStyle: {
                        'background-color': '#000',
                        color: '#fff',
                        padding: '2px',
                        'font-size': '10px'
                    }
                })
            ]
        });

        this.bind();

        this.onSrcChanged();
    }

    load() {
        if (this.waveSurfer) {
            this.isLoading = true;

            this.waveSurfer.load(`${this.src}?_=${this.cacheBustingTag}`);
        }
    }

    reset() {
        if (this.waveSurfer) {
            this.waveSurfer.unAll();
            this.waveSurfer.destroy();
        }
    }

    bind() {
        if (this.waveSurfer) {
            this.waveSurfer.on('ready', this.onReady.bind(this));

            this.waveSurfer.on('audioprocess', this.onSurf.bind(this));

            this.waveSurfer.on('seek', this.onSurf.bind(this));

            this.waveSurfer.on('finish', this.onEnd.bind(this));
        }
    }

    toggle() {
        const context = new AudioContext();
        // see: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
        context.resume().then(() => {
            if (this.waveSurfer) {
                this.waveSurfer.playPause();
                this.isPlaying = !this.isPlaying;
            }
        });
    }

    onReady() {
        if (this.waveSurfer) {
            this.duration = this.formatTimeMark(this.waveSurfer.getDuration());
            this.position = this.formatTimeMark(this.getCurrentTime());
        } else {
            this.duration = '';
        }

        this.isLoading = false;
    }

    onSurf() {
        if (this.waveSurfer) {
            this.position = this.formatTimeMark(this.getCurrentTime());
        }
    }

    getCurrentTime() {
        return Number(this.waveSurfer?.getCurrentTime()) || 0;
    }

    onEnd() {
        if (this.waveSurfer) {
            // resets cursor to the beginning
            this.waveSurfer.stop();

            this.isPlaying = false;

            this.position = this.format(0);
        }
    }

    format(time: number) {
        return formatDate(String(time), this.getPattern(time));
    }

    formatTimeMark(time: number) {
        return this.$dayjs
            .duration(Math.trunc(time * 1000))
            .format(this.getPattern(time));
    }

    getPattern(time: number) {
        if (time < 60) {
            return 'm:ss.SSS';
        } else if (time <= 60 && time < 3600) {
            return 'mm:ss.SSS';
        } else {
            return 'HH:mm:ss.SSS';
        }
    }
}
</script>
