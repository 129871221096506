<template>
    <v-card :outlined="!flat" :flat="flat" class="main-background">
        <v-list-item three-line class="pl-5">
            <v-list-item-content class="py-4">
                <v-list-item-title class="text-4 mt-1 mb-3 font-weight-bold">
                    {{ company.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ company.full_address }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar rounded size="75" class="white">
                <v-icon v-if="!logo" large color="grey lighten-2">image</v-icon>
                <v-img v-else max-width="75" max-height="75" :src="logo" />
            </v-list-item-avatar>
        </v-list-item>
        <v-card-actions v-if="hasButtons" class="px-4 pb-4 pt-0">
            <v-btn
                v-if="company.website"
                rounded
                small
                elevation="0"
                color="white"
                :href="company.website"
                target="_blank"
            >
                <v-icon small color="primary-color" left>globe</v-icon>
                <span class="primary-color--text">Website</span>
            </v-btn>
            <v-btn
                v-if="company.phone && company.display_phone"
                rounded
                small
                icon
                :href="'tel:' + company.phone"
                target="_blank"
            >
                <v-icon small color="primary-color">phone</v-icon>
            </v-btn>
            <v-btn
                v-if="company.facebook_page"
                rounded
                small
                icon
                :href="getUrl('https://facebook.com/', company.facebook_page)"
                target="_blank"
            >
                <v-icon small color="primary-color">fab fa-facebook-f</v-icon>
            </v-btn>
            <v-btn
                v-if="company.twitter_handle"
                rounded
                small
                icon
                :href="getUrl('https://twitter.com/', company.twitter_handle)"
                target="_blank"
            >
                <v-icon small color="primary-color">fab fa-x-twitter</v-icon>
            </v-btn>
            <v-btn
                v-if="company.instagram"
                rounded
                small
                icon
                :href="getUrl('https://instagram.com/', company.instagram)"
                target="_blank"
            >
                <v-icon small color="primary-color">fab fa-instagram</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const CompanyCardSmallOptions = Vue.extend({
    name: 'CompanyCardSmall',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    contact_name: '',
                    name: '',
                    full_address: '',
                    media_resources: [],
                    phone: '',
                    website: '',
                    facebook_page: '',
                    twitter_handle: '',
                    instagram: '',
                    display_phone: false
                };
            }
        },
        flat: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class CompanyCardSmall extends CompanyCardSmallOptions {
    get logo() {
        return (
            this.company.media_resources &&
            this.company.media_resources[0]?.media_file.relative_filename
        );
    }

    get hasButtons() {
        return (
            this.company.phone ||
            this.company.website ||
            this.company.facebook_page ||
            this.company.twitter_handle ||
            this.company.instagram
        );
    }

    getUrl(baseUrl: string, item: string) {
        let cleanedString = item;
        if (item.indexOf('.com') > 0) {
            cleanedString = item
                .replace('facebook', '')
                .replace('fb.me', '')
                .replace('instagram', '')
                .replace('twitter', '')
                .replace('www.', '')
                .replace('.com/', '')
                .replace('https://', '')
                .replace('http://', '');
        }
        return baseUrl + cleanedString;
    }
}
</script>
